/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import Tags from '../components/Tags'
import SEO from '../components/SEO/SEO'
import { graphql } from 'gatsby'
import Time from '../components/Time'
import ViewCounter from '../components/ViewCounter'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import { FormattedMessage } from 'react-intl'
import Share from '../components/Share'
import FaLinkedinIn from '@meronex/icons/fa/FaLinkedinIn'
import BlogNav from '../components/BlogNav'
import Helmet from 'react-helmet'
import BlogNewsletterForm from '../components/NewsletterForm/blog-subscribe'
import { Comment } from 'react-disqus-components'

export const BlogPostTemplate = ({
    date,
    content,
    contentComponent,
    description,
    tags,
    title,
    langKey,
    image,
    imageAlt,
    author,
    authorQuote,
    authorLinkedIn,
    authorPosition,
    authorImage,
    helmet,
    slug,
    id,
    url,
    twitterHandle,
}) => {
    const PostContent = contentComponent || Content
    const socialConfig = {
        twitterHandle,
        config: {
            url: `${url}${slug}`,
            title,
            description,
        },
    }
    return (
        <section className="is-blog-section">
            {helmet || ''}
            <BlogNav />
            <div className="container">
                <div className="columns">
                    <div className="column is-1 is-hidden-touch">
                        <div className="is-display-grid is-sticky-sidebar">
                            <Share socialConfig={socialConfig} tags={tags} />
                        </div>
                    </div>
                    <div className="column is-8 content">
                        <div className="is-image-title-container">
                            <Img
                                className="has-image-filter"
                                alt={imageAlt}
                                fluid={image}
                            />
                            <div className="is-title-absolute-centered">
                                <h1 className="has-text-white has-text-weight-normal">
                                    {title}
                                </h1>
                                <div className="has-text-white has-text-weight-medium">
                                    <Time
                                        pubdate
                                        langKey={langKey}
                                        date={date}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mt-4">
                            <p className="is-blog-content">{description}</p>
                            <PostContent
                                className="is-blog-content"
                                content={content}
                            />
                        </div>

                        <div className="is-font-secondary level is-vcentered is-mobile mt-2">
                            <div className="level-left">
                                <Share
                                    socialConfig={socialConfig}
                                    tags={tags}
                                />
                            </div>
                            <div className="level-right">
                                <div className="is-blog-component-color has-text-weight-medium">
                                    <Tags tags={tags} langKey={langKey} />
                                </div>
                            </div>
                        </div>

                        <div className="is-font-secondary level is-vcentered is-mobile mt-5">
                            <div className="level-left">
                                <figure className="image is-32x32 is-margin-auto-zero">
                                    <Img
                                        className="is-image-radius-circle"
                                        fluid={authorImage}
                                    />
                                </figure>
                                <p className="is-size-6 ml-3 has-text-weight-medium">
                                    {author}
                                </p>
                            </div>
                            <div className="level-right">
                                <ViewCounter id={slug} />
                            </div>
                        </div>
                        <div className="mt-2 column is-8 is-offset-2 ">
                            <h3 className="is-font-secondary has-text-weight-normal has-text-primary is-marginless is-size-6 has-text-centered">
                                <FormattedMessage id="blog.post.newsletter.second" />
                            </h3>
                            <div className="field has-addons mt-2 is-horizontal centered">
                                <BlogNewsletterForm />
                            </div>
                        </div>
                        <div className="mt-2">
                            <Comment
                                title={title}
                                identifier={id}
                                url={url}
                                shortname="4bl"
                            />
                        </div>
                    </div>
                    <div className="column is-font-secondary is-3 is-hidden-touch">
                        <div className="is-sticky-sidebar">
                            <figure className="image is-64x64 is-margin-auto-zero">
                                <Img
                                    className="is-image-radius-circle"
                                    fluid={authorImage}
                                />
                            </figure>
                            <div className="has-text-centered is-subtitle-color mt-4">
                                <h3 className="has-text-weight-medium has-text-dark is-size-6">
                                    {author}
                                </h3>
                                <h3 className="has-text-weight-medium is-size-7">
                                    {authorPosition}
                                </h3>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={authorLinkedIn}
                                >
                                    <FaLinkedinIn
                                        className="is-subtitle-color is-linkedin-icon"
                                        size="1rem"
                                    />
                                </a>
                                <p className="is-size-7">{authorQuote}</p>
                                <div className="mt-2">
                                    <h3 className="has-text-weight-normal has-text-primary is-size-6 has-text-centered">
                                        <FormattedMessage id="blog.post.newsletter.first" />
                                    </h3>
                                    <BlogNewsletterForm />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

BlogPostTemplate.propTypes = {
    content: PropTypes.node.isRequired,
    contentComponent: PropTypes.func,
    description: PropTypes.string,
    title: PropTypes.string,
    id: PropTypes.string,
    helmet: PropTypes.object,
    location: PropTypes.string,
    tags: PropTypes.array,
    langKey: PropTypes.string,
    image: PropTypes.object,
    ogImage: PropTypes.object,
    imageAlt: PropTypes.string,
    author: PropTypes.string,
    authorLinkedIn: PropTypes.string,
    authorPosition: PropTypes.string,
    date: PropTypes.string,
    authorQuote: PropTypes.string,
    slug: PropTypes.string,
    authorImage: PropTypes.object,
    timeToRead: PropTypes.number,
    data: PropTypes.object,
    url: PropTypes.string,
    twitterHandle: PropTypes.string,
}

const BlogPost = ({ data, location }) => {
    const { markdownRemark: post } = data
    const jsonData = data.allArticlesJson.edges[0].node.articles
    const langKey = post.frontmatter.lang
    const image = post.frontmatter.image.childImageSharp.fluid
    const ogImage = post.frontmatter.image.childImageSharp.resize.src
    const meta = data.site.siteMetadata
    return (
        <Layout
            className="container"
            data={data}
            jsonData={jsonData}
            location={location}
        >
            <SEO frontmatter={post.frontmatter} ogImage={ogImage} isBlogPost />
            <BlogPostTemplate
                content={post.html}
                contentComponent={HTMLContent}
                description={post.frontmatter.description}
                image={image}
                author={post.frontmatter.author}
                timeToRead={post.timeToRead}
                authorImage={post.frontmatter.authorImage.childImageSharp.fluid}
                authorQuote={post.frontmatter.authorQuote}
                authorPosition={post.frontmatter.authorPosition}
                authorLinkedIn={post.frontmatter.authorLinkedIn}
                tags={post.frontmatter.tags}
                title={post.frontmatter.title}
                id={post.frontmatter.id}
                date={post.frontmatter.date}
                slug={post.frontmatter.slug}
                helmet={
                    <Helmet
                    >
                      <meta name="description" content={`${post.frontmatter.description}`} />
                      <meta name="author" content={`${post.frontmatter.author}`} />
                      <meta name='date' content={`${post.frontmatter.date}`}/>

                    </Helmet>
                  }
                url={meta.siteUrl}
                twitterHandle={meta.social.twitter}
                langKey={langKey}
            />
        </Layout>
    )
}

BlogPost.propTypes = {
    author: PropTypes.string,
    imageAlt: PropTypes.string,
    data: PropTypes.shape({
        markdownRemark: PropTypes.object,
        site: PropTypes.object,
        allArticlesJson: PropTypes.object,
    }),
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
}

export default BlogPost

export const pageQuery = graphql`
    query BlogPostByID($id: String!) {
        site {
            siteMetadata {
                social {
                    twitter
                }
                siteUrl
                title
                languages {
                    langs
                    defaultLangKey
                }
            }
        }
        allArticlesJson(filter: { title: { eq: "home" } }) {
            edges {
                node {
                    articles {
                        en
                        ka
                    }
                }
            }
        }
        markdownRemark(id: { eq: $id }) {
            id
            html
            timeToRead
            frontmatter {
                slug
                id
                title
                imageAlt
                image {
                    childImageSharp {
                        fluid(maxWidth: 1200) {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                        resize(width: 900, quality: 100) {
                            src
                        }
                    }
                }
                description
                date
                tags
                lang
                author
                authorLinkedIn
                authorPosition
                authorQuote
                authorImage {
                    childImageSharp {
                        fluid(maxWidth: 64, quality: 100) {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
            }
        }
    }
`
